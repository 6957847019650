import { useCallback, useEffect, useState } from 'react'

import BigNumber from 'bignumber.js'
import useColx from './useColx'
import { Contract } from 'web3-eth-contract'

import { useActiveWeb3React } from './useWeb3'
import { getAllowance } from '../utils/erc20'
import { getMasterChefContract } from '../colx/utils'

const useAllowance = (lpContract: Contract) => {
  const [allowance, setAllowance] = useState(new BigNumber(0))
  const { account } = useActiveWeb3React()
  const colx = useColx()
  const masterChefContract = getMasterChefContract(colx)

  const fetchAllowance = useCallback(async () => {
  const allowance = await getAllowance(
      lpContract,
      account,
      masterChefContract.options.address,
    )
    setAllowance(new BigNumber(allowance))
  }, [account, masterChefContract, lpContract])

  useEffect(() => {
    if (account && masterChefContract && lpContract) {
      fetchAllowance()
    }
    let refreshInterval = setInterval(fetchAllowance, 10000)
    return () => clearInterval(refreshInterval)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [account, masterChefContract, lpContract])

  return allowance
}

export default useAllowance
