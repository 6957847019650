import { useCallback } from 'react'

import useColx from './useColx'
import { useActiveWeb3React } from './useWeb3'

import { stake, getMasterChefContract } from '../colx/utils'

const useStake = (pid: number) => {
  const { account } = useActiveWeb3React()
  const colx = useColx()

  const handleStake = useCallback(
    async (amount: string) => {
      const txHash = await stake(
        getMasterChefContract(colx),
        pid,
        amount,
        account,
      )
      console.log(txHash)
    },
    [account, pid, colx],
  )

  return { onStake: handleStake }
}

export default useStake
