import React, { useEffect, useMemo } from 'react'
// import { useParams } from 'react-router-dom'
import styled from 'styled-components'
import { useActiveWeb3React } from '../../hooks/useWeb3'
import { provider } from 'web3-core'
import PageHeader from '../../components/PageHeader'
import Spacer from '../../components/Spacer'
import useFarm from '../../hooks/useFarm'
import { getContract } from '../../utils/erc20'
import Harvest from './components/Harvest'
import Stake from './components/Stake'

const Farm: React.FC = () => {
  // @ts-ignore
  // const { farmId } = useParams()
  const farmId = 'bCOLX - BUSD LP'
  const {
    pid,
    lpToken,
    lpTokenAddress,
    earnToken,
    name,
    icon,
    icon2,
    addLiquidityLink,
  } = useFarm(farmId) || {
    pid: 0,
    lpToken: '',
    lpTokenAddress: '',
    tokenAddress: '',
    earnToken: '',
    name: '',
    icon: '',
    icon2: '',
    addLiquidityLink: '',
  }

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  const { library } = useActiveWeb3React()

  const lpContract = useMemo(() => {
    return getContract(library as provider, lpTokenAddress)
  }, [library, lpTokenAddress])

  const lpTokenName = useMemo(() => {
    return lpToken
  }, [lpToken])

  const earnTokenName = useMemo(() => {
    return earnToken.toUpperCase()
  }, [earnToken])

  return (
    <>
      <PageHeader
        icon={icon}
        icon2={icon2}
        subtitle={`Deposit ${lpTokenName}  Tokens and earn ${earnTokenName}`}
        title={name}
      />
      <StyledFarm>
        <StyledCardsWrapper>
          <StyledCardWrapper>
            <Harvest pid={pid} />
          </StyledCardWrapper>
          <Spacer />
          <StyledCardWrapper>
            <Stake
              lpContract={lpContract}
              pid={pid}
              tokenName={lpToken}
              icon={icon}
              icon2={icon2}
            />
          </StyledCardWrapper>
        </StyledCardsWrapper>
        <Spacer size="lg" />
        <StyledLink
          target="__blank"
          href={`https://pancakeswap.info/pool/${lpTokenAddress}`}
        >
          {lpTokenName} Info
        </StyledLink>
        <StyledLink
          target="__blank"
          href={addLiquidityLink}
        >
          Add Liquidity
        </StyledLink>
      </StyledFarm>
    </>
  )
}

const StyledFarm = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  @media (max-width: 768px) {
    width: 100%;
  }
`

const StyledCardsWrapper = styled.div`
  display: flex;
  width: 600px;
  @media (max-width: 768px) {
    width: 100%;
    flex-flow: column nowrap;
    align-items: center;
  }
`

const StyledCardWrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  @media (max-width: 768px) {
    width: 80%;
  }
`

const StyledLink = styled.a`
  color: ${(props) => props.theme.color.grey[400]};
  padding-left: ${(props) => props.theme.spacing[3]}px;
  padding-right: ${(props) => props.theme.spacing[3]}px;
  text-decoration: underline;
  &:hover {
    color: ${(props) => props.theme.color.grey[500]};
  }
`
export default Farm
