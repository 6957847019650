import { useCallback, useEffect, useState } from 'react'

import BigNumber from 'bignumber.js'
import { useActiveWeb3React } from './useWeb3'

import { getEarned, getMasterChefContract } from '../colx/utils'
import useColx from './useColx'
import useBlock from './useBlock'

const useEarnings = (pid: number) => {
  const [balance, setBalance] = useState(new BigNumber(0))
  const { account } = useActiveWeb3React()
  const colx = useColx()
  const masterChefContract = getMasterChefContract(colx)
  const block = useBlock()

  const fetchBalance = useCallback(async () => {
    const balance = await getEarned(masterChefContract, pid, account)
    setBalance(new BigNumber(balance))
  }, [account, masterChefContract, colx])

  useEffect(() => {
    if (account && masterChefContract && colx) {
      fetchBalance()
    }
  }, [account, block, masterChefContract, setBalance, colx])

  return balance
}

export default useEarnings
