import { useEffect, useState } from 'react'
import Web3 from 'web3'
import { useActiveWeb3React } from './useWeb3'

const useBlock = () => {
  const [block, setBlock] = useState(0)
  const { library } = useActiveWeb3React()

  useEffect(() => {
    // const setBlockDebounced = debounce(setBlock, 300)
    const web3 = new Web3(library)

    const interval = setInterval(async () => {
      const latestBlockNumber = await web3.eth.getBlockNumber()
      if (block !== latestBlockNumber) {
        setBlock(latestBlockNumber)
      }
    }, 1000)

    return () => clearInterval(interval)
  }, [library])

  return block
}

export default useBlock
