import BigNumber from 'bignumber.js'
import React from 'react'
import styled from 'styled-components'
import Button from '../../../components/Button'
import Card from '../../../components/Card'
import CardContent from '../../../components/CardContent'
import Loader from '../../../components/Loader'
import Spacer from '../../../components/Spacer'
import { Farm } from '../../../contexts/Farms'
import useAllStakedValue, {
  StakedValue,
} from '../../../hooks/useAllStakedValue'
import useFarms from '../../../hooks/useFarms'

interface FarmWithStakedValue extends Farm, StakedValue {
  apy: BigNumber
  usdValue: BigNumber
}

const FarmCards: React.FC = () => {
  const [farms] = useFarms()
  const stakedValue = useAllStakedValue()

  const colxIndex = farms.findIndex(
    ({ tokenSymbol }) => tokenSymbol === 'bCOLX',
  )

  const colxPrice =
    colxIndex >= 0 && stakedValue[colxIndex]
      ? stakedValue[colxIndex].tokenPriceInWeth
      : new BigNumber(0)

  const BCOLX_REWARDS = new BigNumber(100000)

  const rows = farms.reduce<FarmWithStakedValue[][]>(
    (farmRows, farm, i) => {
      const farmWithStakedValue = {
        ...farm,
        ...stakedValue[i],
        apy: stakedValue[i]
          ? (BCOLX_REWARDS.times(colxPrice)).div(stakedValue[i].wethAmount).times(new BigNumber(365))
          // (100,000 COLX * COLX marketprice in USD) / (Pancakeswap bCOLX BUSD pool liquidity in USD) * 365
          : null,
        usdValue: (stakedValue[i] || {}).usdValue || new BigNumber(0)
      }
      const newFarmRows = [...farmRows]
      if (newFarmRows[newFarmRows.length - 1].length === 3) {
        newFarmRows.push([farmWithStakedValue])
      } else {
        newFarmRows[newFarmRows.length - 1].push(farmWithStakedValue)
      }
      return newFarmRows
    },
    [[]],
  )

  return (
    <StyledCards>
      {!!rows[0].length ? (
        rows.map((farmRow, i) => (
          <StyledRow key={i}>
            {farmRow.map((farm, j) => (
              <React.Fragment key={j}>
                <FarmCard farm={farm} />
                {(j === 0 || j === 1) && <StyledSpacer />}
              </React.Fragment>
            ))}
          </StyledRow>
        ))
      ) : (
        <StyledLoadingWrapper>
          <Loader text="Loading ..." />
        </StyledLoadingWrapper>
      )}
    </StyledCards>
  )
}

interface FarmCardProps {
  farm: FarmWithStakedValue
}

const FarmCard: React.FC<FarmCardProps> = ({ farm }) => {

  return (
    <StyledCardWrapper>
      {farm.tokenSymbol === 'bCOLX' && <StyledCardAccent />}
      <Card>
        <CardContent>
          <StyledContent>
            <FarmIcons>
              <img src={farm.icon} alt="" />
              <Spacer />
              <img src={farm.icon2} alt="" />
            </FarmIcons>
            <Spacer />
            <StyledTitle>{farm.name}</StyledTitle>
            <StyledDetails>
              <StyledDetail>Deposit {farm.lpToken}</StyledDetail>
              <StyledDetail>Earn {farm.earnToken.toUpperCase()}</StyledDetail>
            </StyledDetails>
            <Spacer />
            <Button
              text="Select"
              // to={`/farms/${farm.id}`}
              to={`/farming`}
            />
            <Spacer size="lg" />
            <StyledInsight>
              <span>TVL</span>
              <span>{parseFloat(farm.usdValue.toFixed(0)).toLocaleString('en-US')} USD</span>
            </StyledInsight>
            <StyledInsight>
              <span>APY</span>
              <span>
                {farm.apy
                  ? `${farm.apy
                      .times(new BigNumber(100))
                      .toNumber()
                      .toLocaleString('en-US')
                      .slice(0, -1)}%`
                  : 'Loading ...'}
              </span>
            </StyledInsight>
          </StyledContent>
        </CardContent>
      </Card>
    </StyledCardWrapper>
  )
}

const StyledCardAccent = styled.div`
  box-shadow: 0 0 10px ${props => props.theme.color.green[500]};
  filter: blur(6px);
  position: absolute;
  top: -2px;
  right: -2px;
  bottom: -2px;
  left: -2px;
  z-index: -1;
`

const StyledCards = styled.div`
  width: 900px;
  @media (max-width: 768px) {
    width: 100%;
  }
`

const StyledLoadingWrapper = styled.div`
  align-items: center;
  display: flex;
  flex: 1;
  justify-content: center;
`

const StyledRow = styled.div`
  display: flex;
  margin-bottom: ${(props) => props.theme.spacing[4]}px;
  flex-flow: row wrap;
  justify-content: center;
  @media (max-width: 768px) {
    width: 100%;
    flex-flow: column nowrap;
    align-items: center;
  }
`

const StyledCardWrapper = styled.div`
  display: flex;
  width: calc((900px - ${(props) => props.theme.spacing[4]}px * 2) / 3);
  position: relative;
`

const StyledTitle = styled.h4`
  color: ${(props) => props.theme.color.white};
  font-size: 24px;
  font-weight: 700;
  margin: ${(props) => props.theme.spacing[2]}px 0 0;
  padding: 0;
`

const StyledContent = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
`

const StyledSpacer = styled.div`
  height: ${(props) => props.theme.spacing[4]}px;
  width: ${(props) => props.theme.spacing[4]}px;
`

const StyledDetails = styled.div`
  margin-top: ${(props) => props.theme.spacing[2]}px;
  text-align: center;
`

const StyledDetail = styled.div`
  color: ${(props) => props.theme.color.grey[500]};
`

const StyledInsight = styled.div`
  display: flex;
  justify-content: space-between;
  box-sizing: border-box;
  color: ${props => props.theme.color.grey[400]};
  width: 100%;
  line-height: 32px;
  font-family: 'Share Tech Mono', sans-serif;
  font-size: 14px;
  text-align: center;
`

const FarmIcons = styled.div`
  display: flex;
  align-items: center;
  padding-top: 1rem;

  img {
    width: 50px;
  }
`
export default FarmCards
