import React from 'react'
import styled from 'styled-components'

import Container from '../Container'
import Spacer from '../../components/Spacer'

interface PageHeaderProps {
  icon: string
  icon2?: string
  subtitle?: string
  title?: string
}

const PageHeader: React.FC<PageHeaderProps> = ({ icon, icon2, subtitle, title }) => {
  return (
    <Container size="sm">
      <StyledPageHeader>
        <FarmIcons>
          <img src={icon} alt="" />
          <Spacer />
          {icon2 && (
            <img src={icon2} alt="" />
          )}
        </FarmIcons>
        <Spacer />
        <StyledTitle>{title}</StyledTitle>
        <StyledSubtitle>{subtitle}</StyledSubtitle>
      </StyledPageHeader>
    </Container>
  )
}

const StyledPageHeader = styled.div`
  align-items: center;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  padding-bottom: ${(props) => props.theme.spacing[6]}px;
  padding-top: ${(props) => props.theme.spacing[6]}px;
  margin: 0 auto;
`

const StyledTitle = styled.h1`
  font-family: 'Share Tech Mono', sans-serif;
  color: ${(props) => props.theme.color.white};
  font-size: 36px;
  text-align: center;
  font-weight: 700;
  margin: 0;
  padding: 0;
`

const StyledSubtitle = styled.h3`
  color: ${(props) => props.theme.color.grey[400]};
  font-size: 18px;
  font-weight: 400;
  margin: 0;
  padding: 0;
  text-align: center;
`

const FarmIcons = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 1rem;

  img {
    width: 50px;
  }
`

export default PageHeader
