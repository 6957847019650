import React from 'react'
import { useWeb3React, UnsupportedChainIdError } from '@web3-react/core'
import {
  NoEthereumProviderError,
  UserRejectedRequestError as UserRejectedRequestErrorInjected,
} from '@web3-react/injected-connector'
import {
  UserRejectedRequestError as UserRejectedRequestErrorWalletConnect,
  WalletConnectConnector,
} from '@web3-react/walletconnect-connector'
import styled from 'styled-components'
import { toast } from 'react-toastify'
import  { ConnectorNames, connectorsByName } from '../../connectors'
import { connectorLocalStorageKey } from '../../constants'
import metamaskLogo from '../../assets/img/metamask-fox.svg'
import walletConnectLogo from '../../assets/img/wallet-connect.svg'
import ToastMessage from '../ToastMessage'
import Button from '../Button'
import Modal, { ModalProps } from '../Modal'
import ModalActions from '../ModalActions'
import ModalContent from '../ModalContent'
import ModalTitle from '../ModalTitle'
import Spacer from '../Spacer'

import WalletCard from './components/WalletCard'

const WalletProviderModal: React.FC<ModalProps> = ({ onDismiss }) => {
  const { activate } = useWeb3React()

  const onConnectWallet = async (connectorID: ConnectorNames) => {
    const connector = connectorsByName[connectorID]

    if (connector) {
      window.localStorage.setItem(connectorLocalStorageKey, connectorID)

      await activate(connector, async (error: Error) => {
        if (error instanceof UnsupportedChainIdError) {
          toast.error(
            <ToastMessage color="danger" headerText="Wrong network" bodyText="Please check your chain id." />,
            {
              toastId: connectorID,
            },
          )
        } else if (error instanceof NoEthereumProviderError) {
          toast.error(<ToastMessage color="danger" headerText="No provider was found" />, {
            toastId: connectorID,
          })
        } else if (
          error instanceof UserRejectedRequestErrorInjected ||
          error instanceof UserRejectedRequestErrorWalletConnect
        ) {
          if (connector instanceof WalletConnectConnector) {
            const walletConnector = connector as WalletConnectConnector
            walletConnector.walletConnectProvider = null
          }
          toast.error(<ToastMessage color="danger" headerText="Please authorize to access your account" />, {
            toastId: connectorID,
          })
        } else {
          toast.error(<ToastMessage color="danger" headerText={error.name} bodyText={error.message} />, {
            toastId: connectorID,
          })
        }
      })
    } else {
      toast.error(<ToastMessage color="danger" headerText="Can't find connector" />, {
        toastId: connectorID,
      })
    }

    onDismiss()
  }

  return (
    <Modal>
      <ModalTitle text="Select a wallet provider." />

      <ModalContent>
        <StyledWalletsWrapper>
          <StyledWalletCard>
            <WalletCard
              icon={<img src={metamaskLogo} style={{ height: 32 }} alt="MetaMask" />}
              onConnect={() => onConnectWallet(ConnectorNames.Injected)}
              title="Metamask"
            />
          </StyledWalletCard>
          <Spacer size="sm" />
          <StyledWalletCard>
            <WalletCard
              icon={<img src={walletConnectLogo} style={{ height: 24 }}  alt="Logo"/>}
              onConnect={() => onConnectWallet(ConnectorNames.WalletConnect)}
              title="WalletConnect"
            />
          </StyledWalletCard>
        </StyledWalletsWrapper>
      </ModalContent>

      <ModalActions>
        <Button text="Cancel" variant="secondary" size="sm" onClick={onDismiss} />
      </ModalActions>
    </Modal>
  )
}

const StyledWalletsWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  @media (max-width: ${(props) => props.theme.breakpoints.mobile}px) {
    flex-direction: column;
    flex-wrap: none;
  }
`

const StyledWalletCard = styled.div`
  flex-basis: calc(50% - ${(props) => props.theme.spacing[2]}px);
`

export default WalletProviderModal
