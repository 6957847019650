import React from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import LogoSVG from '../../assets/img/logo.svg'

const Logo: React.FC = () => (
  <StyledLogo to="/">
    <img src={LogoSVG} alt="Logo" />
  </StyledLogo>
)

const StyledLogo = styled(Link)`
  align-items: center;
  display: flex;
  justify-content: center;
  margin: 0;
  min-height: 44px;
  min-width: 44px;
  padding: 0;
  text-decoration: none;
`

export default Logo
