export const white = '#FFF'
export const black = '#000000'

export const green = {
  300: '#8fc1ad',
  500: '#35ba85',
}

export const red = {
  100: '#FFFDFE',
  200: '#ffc2a8',
  500: '#d16c00',
}

export const grey = {
  100: '#f7f4f2',
  200: '#f8f8f8',
  300: '#A3D0BD',
  400: '#CFCFFF',
  500: '#7ba795',
  600: '#5b3926',
  800: '#2d1e12',
}
