import React, { useState } from 'react'

import useColx from '../../hooks/useColx'

import { getFarms } from '../../colx/utils'

import Context from './context'

const Farms: React.FC = ({ children }) => {
  const [unharvested] = useState(0)

  const colx = useColx()

  const farms = getFarms(colx)

  return (
    <Context.Provider
      value={{
        farms,
        unharvested,
      }}
    >
      {children}
    </Context.Provider>
  )
}

export default Farms
