import React from 'react'
import styled from 'styled-components'

interface CardProps {
  backgroundColor?: string
  children: JSX.Element | JSX.Element[]
}

const Card: React.FC<CardProps> = ({ backgroundColor, children }) => <StyledCard color={backgroundColor}>{children}</StyledCard>

const StyledCard = styled.div`
  background-color: ${props => props.color ?? '#272524'};
  border: 1px solid ${(props) => props.theme.color.black}1a;
  display: flex;
  flex: 1;
  flex-direction: column;
`

export default Card
