import BigNumber from 'bignumber.js/bignumber'

export const SUBTRACT_GAS_LIMIT = 100000

const ONE_MINUTE_IN_SECONDS = new BigNumber(60)
const ONE_HOUR_IN_SECONDS = ONE_MINUTE_IN_SECONDS.times(60)
const ONE_DAY_IN_SECONDS = ONE_HOUR_IN_SECONDS.times(24)
const ONE_YEAR_IN_SECONDS = ONE_DAY_IN_SECONDS.times(365)

export const INTEGERS = {
  ONE_MINUTE_IN_SECONDS,
  ONE_HOUR_IN_SECONDS,
  ONE_DAY_IN_SECONDS,
  ONE_YEAR_IN_SECONDS,
  ZERO: new BigNumber(0),
  ONE: new BigNumber(1),
  ONES_31: new BigNumber('4294967295'), // 2**32-1
  ONES_127: new BigNumber('340282366920938463463374607431768211455'), // 2**128-1
  ONES_255: new BigNumber(
    '115792089237316195423570985008687907853269984665640564039457584007913129639935',
  ), // 2**256-1
  INTEREST_RATE_BASE: new BigNumber('1e18'),
}

export const contractAddresses = {
  bCOLX: {
    1: '0x6b3595068778dd592e39a122f4f5a5cf09c90fe2',
    42: '0xE714A0ae3e69dFFd3229B8a49C6dEFC2E8397aFc',
    56: '0xf8acf86194443dcde55fc5b9e448e183c290d8cb',
  },
  masterChef: {
    1: '0xc2edad668740f1aa35e4d8f227fb8e17dca888cd',
    42: '0x87FE794378DdB8c8A736CA573171BF5D65fE408C',
    56: '0xD04C8551893eb11D771C6481e5115E11434e9d18',
  },
  weth: {
    1: '0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2',
    42: '0xd0a1e359811322d97991e03f863a0c30c2cf029c',
    56: '0xe9e7cea3dedca5984780bafc599bd69add087d56',
  },
  staking: {
    1: '0x8798249c2e607446efb7ad49ec89dd1865ff4272',
    42: '0x87FE794378DdB8c8A736CA573171BF5D65fE408C',
    56: '0xD04C8551893eb11D771C6481e5115E11434e9d18',
  }
}

export const supportedPools = [
  // Perm Menu
  {
    pid: 1,
    lpAddresses: {
      1: '0x795065dCc9f64b5614C407a6EFDC400DA6221FB0',
      42: '0x112A73576698b38fcC74153b4311dCDAbC9bA957',
      56: '0x01e95aa785da1d4672972b51871287cb87c437a7',
    },
    tokenAddresses: {
      1: '0x6b3595068778dd592e39a122f4f5a5cf09c90fe2',
      42: '0xE714A0ae3e69dFFd3229B8a49C6dEFC2E8397aFc',
      56: '0xf8acf86194443dcde55fc5b9e448e183c290d8cb',
    },
    name: 'bCOLX - BUSD',
    symbol: 'bCOLX - BUSD LP',
    tokenSymbol: 'bCOLX',
    icon: 'https://assets.coingecko.com/coins/images/966/large/colossusxt.png',
    icon2: 'https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/binance/assets/BUSD-BD1/logo.png',
    addLiquidityLink: 'https://pancakeswap.finance/add/0xF8aCF86194443DCde55fc5B9e448e183c290D8Cb/0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56'
  },
]
