import { useCallback, useEffect, useState } from 'react'

import BigNumber from 'bignumber.js'

import { useActiveWeb3React } from '../hooks/useWeb3'
import { getEarned, getMasterChefContract, getFarms } from '../colx/utils'
import useColx from './useColx'
import useBlock from './useBlock'

const useAllEarnings = () => {
  const [balances, setBalance] = useState([] as Array<BigNumber>)
  const { account }= useActiveWeb3React()
  const colx = useColx()
  const farms = getFarms(colx)
  const masterChefContract = getMasterChefContract(colx)
  const block = useBlock()

  const fetchAllBalances = useCallback(async () => {
    const balances: Array<BigNumber> = await Promise.all(
      farms.map(({ pid }: { pid: number }) =>
        getEarned(masterChefContract, pid, account),
      ),
    )
    setBalance(balances)
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [account, masterChefContract, colx])

    useEffect(() => {
      if (account && masterChefContract && colx) {
        fetchAllBalances()
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [account, block, masterChefContract, setBalance, colx])

  return balances
}

export default useAllEarnings
