import React, { useCallback } from 'react'
import styled from 'styled-components'
import { useActiveWeb3React } from '../../../hooks/useWeb3'
import useTokenBalance from '../../../hooks/useTokenBalance'
import useColx from '../../../hooks/useColx'
import { getColxAddress } from '../../../colx/utils'
import { getDisplayBalance } from '../../../utils/formatBalance'
import Button from '../../Button'
import CardIcon from '../../CardIcon'
import Label from '../../Label'
import Modal, { ModalProps } from '../../Modal'
import ModalActions from '../../ModalActions'
import ModalContent from '../../ModalContent'
import ModalTitle from '../../ModalTitle'
import Spacer from '../../Spacer'
import Value from '../../Value'
import bCOLXLogo from '../../../assets/img/bcolx-logo.svg'

const AccountModal: React.FC<ModalProps> = ({ onDismiss }) => {
  const { account, deactivate } = useActiveWeb3React()

  const handleSignOutClick = useCallback(() => {
    onDismiss!()
    deactivate()
  }, [onDismiss, deactivate])

  const colx = useColx()
  const colxBalance = useTokenBalance(getColxAddress(colx))

  return (
    <Modal>
      <ModalTitle text="My Account" />
      <ModalContent>
        <Spacer />

        <div style={{ display: 'flex' }}>
          <StyledBalanceWrapper>
            <CardIcon>
              <img src={bCOLXLogo} alt="bCOLX Logo" />
            </CardIcon>
            <StyledBalance>
              <Value value={getDisplayBalance(colxBalance)} />
              <Label text="bCOLX Balance" />
            </StyledBalance>
          </StyledBalanceWrapper>
        </div>

        <Spacer />
        <Button
          href={`https://bscscan.com/address/${account}`}
          text="View on BSCScan"
          variant="secondary"
        />
        <Spacer />
        <Button
          onClick={handleSignOutClick}
          text="Sign out"
          variant="secondary"
        />
      </ModalContent>
      <ModalActions>
        <Button onClick={onDismiss} text="Cancel" />
      </ModalActions>
    </Modal>
  )
}

const StyledBalance = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
`

const StyledBalanceWrapper = styled.div`
  align-items: center;
  display: flex;
  flex: 1;
  flex-direction: column;
  margin-bottom: ${(props) => props.theme.spacing[4]}px;
`

export default AccountModal
