import { useCallback } from 'react'

import useColx from './useColx'
import { useActiveWeb3React } from './useWeb3'
import { Contract } from 'web3-eth-contract'

import { approve, getMasterChefContract } from '../colx/utils'

const useApprove = (lpContract: Contract) => {
  const { account } = useActiveWeb3React()
  const colx = useColx()
  const masterChefContract = getMasterChefContract(colx)

  const handleApprove = useCallback(async () => {
    try {
      const tx = await approve(lpContract, masterChefContract, account)
      return tx
    } catch (e) {
      return false
    }
  }, [account, lpContract, masterChefContract])

  return { onApprove: handleApprove }
}

export default useApprove
