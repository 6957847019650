import React from 'react'
import { IoLockClosedOutline } from 'react-icons/io5'
import { useActiveWeb3React } from '../../hooks/useWeb3'
import Container from '../../components/Container'
import Page from '../../components/Page'
import Spacer from '../../components/Spacer'
import Balances from './components/Balances'
import TotalValueLocked from './components/TotalValueLocked'
import Button from '../../components/Button'
import WalletProviderModal from '../../components/WalletProviderModal'
import FarmCards from './components/FarmCards'
import useModal from '../../hooks/useModal'

const Home: React.FC = () => {
  const { account } = useActiveWeb3React()
  const [onPresentWalletProviderModal] = useModal(<WalletProviderModal />)

  return (
    <Page>
      <Container>
        <TotalValueLocked />
      </Container>
      <Container>
        <Balances />
      </Container>

      {!!account ? (
        <>
          <Spacer size="lg" />
          <Spacer size="lg" />
          <FarmCards />
        </>
      ) : (
        <div
          style={{
            alignItems: 'center',
            display: 'flex',
            flex: 1,
            justifyContent: 'center',
          }}
        >
          <Button
            onClick={onPresentWalletProviderModal}
            text={<span><IoLockClosedOutline size="22" /> Unlock Wallet</span>}
          />
        </div>
      )}
    </Page>
  )
}

export default Home
