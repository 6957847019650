import { useCallback, useEffect, useState } from 'react'

import BigNumber from 'bignumber.js'
import { useActiveWeb3React } from './useWeb3'

import { getBalance } from '../utils/erc20'
import useBlock from './useBlock'

import { contractAddresses } from '../colx/lib/constants'

const useMasterChefBalance = (tokenAddress: string) => {
  const [balance, setBalance] = useState(new BigNumber(0))
  const { account, chainId, library } = useActiveWeb3React()
  const block = useBlock()

  const fetchBalance = useCallback(async () => {
    // @ts-ignore
    const balance = await getBalance(library, tokenAddress, contractAddresses.masterChef[chainId])
    setBalance(new BigNumber(balance))
  }, [account, library, tokenAddress])

  useEffect(() => {
    if (account && library) {
      fetchBalance()
    }
  }, [account, library, setBalance, block, tokenAddress])

  return balance
}

export default useMasterChefBalance
