import { useCallback } from 'react'

import useColx from './useColx'
import { useActiveWeb3React } from './useWeb3'

import { unstake, getMasterChefContract } from '../colx/utils'

const useUnstake = (pid: number) => {
  const { account } = useActiveWeb3React()
  const colx = useColx()
  const masterChefContract = getMasterChefContract(colx)

  const handleUnstake = useCallback(
    async (amount: string) => {
      const txHash = await unstake(masterChefContract, pid, amount, account)
      console.log(txHash)
    },
      // eslint-disable-next-line react-hooks/exhaustive-deps
    [account, pid, colx],
  )

  return { onUnstake: handleUnstake }
}

export default useUnstake
