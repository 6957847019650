import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import CountUp from 'react-countup'
import BigNumber from 'bignumber.js'
import useAllStakedValue from '../../../hooks/useAllStakedValue'

const StyledHeading = styled.h3`
  padding-bottom: ${(props) => props.theme.spacing[6]}px;
  padding-top: ${(props) => props.theme.spacing[6]}px;
  text-align: center;
  font-size: 2rem;
  font-weight: 700;
  color: #fff;
`

const TotalValueLocked: React.FC = () => {
    const allStakedValue = useAllStakedValue()
    const [start, setStart] = useState(0)
    const [end, setEnd] = useState(0)
    const [scale, setScale] = useState(1)

    let sumEarning = new BigNumber(0)
    for (const e of allStakedValue) {
      sumEarning = sumEarning.plus(e.usdValue)
    }

    useEffect(() => {
      const fetchData = async () => {
          setStart(end)
          setEnd(sumEarning.toNumber())
      }

      fetchData()
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [sumEarning])

    return (
        <StyledHeading>
          Total Value Locked:{' '}
          <span
            style={{
              color: '#35ba85',
              transform: `scale(${scale})`,
              transformOrigin: 'right bottom',
              transition: 'transform 0.5s',
              display: 'inline-block',
            }}
          >
            <CountUp
              start={start}
              end={end}
              decimals={end < 0 ? 4 : end > 1e5 ? 0 : 3}
              duration={1}
              onStart={() => {
                setScale(1.05)
                setTimeout(() => setScale(1), 600)
              }}
              prefix="$"
              separator=","
            />
          </span>
        </StyledHeading>
    )
}

export default TotalValueLocked
