import { useCallback } from 'react'

import useColx from './useColx'
import { useActiveWeb3React } from './useWeb3'

import { harvest, getMasterChefContract } from '../colx/utils'

const useReward = (pid: number) => {
  const { account } = useActiveWeb3React()
  const colx = useColx()
  const masterChefContract = getMasterChefContract(colx)

  const handleReward = useCallback(async () => {
    const txHash = await harvest(masterChefContract, pid, account)
    console.log(txHash)
    return txHash
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [account, pid, colx])

  return { onReward: handleReward }
}

export default useReward
