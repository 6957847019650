import React, { createContext, useEffect, useState } from 'react'

import { useActiveWeb3React } from '../../hooks/useWeb3'

import { Colx } from '../../colx'

export interface ColxContext {
  colx?: typeof Colx
}

export const Context = createContext<ColxContext>({
  colx: undefined,
})

declare global {
  interface Window {
    colx: any
  }
}

const ColxProvider: React.FC = ({ children }) => {
  const { account, chainId, library } = useActiveWeb3React()
  const [colx, setColx] = useState<any>()

  // @ts-ignore
  window.colx = colx
  // @ts-ignore

  useEffect(() => {
    if (library) {
      const colxLib = new Colx(library, chainId, false, {
        defaultAccount: account,
        defaultConfirmations: 1,
        autoGasMultiplier: 1.5,
        testing: false,
        defaultGas: '6000000',
        defaultGasPrice: '1000000000000',
        accounts: [],
        ethereumNodeTimeout: 10000,
      })
      setColx(colxLib)
      window.colx = colxLib
    }
  }, [account, chainId, library])

  return <Context.Provider value={{ colx }}>{children}</Context.Provider>
}

export default ColxProvider
